import { useRouter } from 'next/router';
import { portfolioURL } from '@/components/portfolio/lib/portfolioURL';
import { useFormResetContext } from '@/helpers/hooks/formResetContext';
import { Constants } from '@/helpers/utils';
import useAuthStore from '@/stores/authStore';
import { useAuthModal } from '@/stores/modalStore';
import {
  faBell,
  faBookReader,
  faChartPie,
  faCompass,
  faHeart,
  faHome,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type TNavItem = {
  id: string;
  label: React.ReactNode;
  href: string;
  icon?: React.ReactNode;
  isCurrent?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const useAsideMenuData = (): TNavItem[] => {
  const { openAuthModal } = useAuthModal();
  const isLoggedIn = useAuthStore((state) => Boolean(state.authToken));
  const router = useRouter();

  const handleLogin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    openAuthModal();
  };

  const { setTriggerReset, setTriggerResetTable } = useFormResetContext();

  return [
    {
      label: 'Home',
      icon: <FontAwesomeIcon icon={faHome} width={16} />,
      id: 'home',
      isCurrent:
        router.pathname === Constants.ERoutePath.home ||
        router.pathname === Constants.ERoutePath.topTokensMarketCap ||
        router.pathname === Constants.ERoutePath.newCoins,
      href: Constants.ERoutePath.home,
    },
    {
      label: 'Explore',
      icon: <FontAwesomeIcon icon={faCompass} width={16} />,
      id: 'token-explorer',
      isCurrent: router.pathname === Constants.ERoutePath.tokenExplorer,
      href: Constants.ERoutePath.tokenExplorer,
      onClick: () => {
        setTriggerReset(true);
        setTriggerResetTable(true);
      },
    },
    {
      label: 'Learn',
      icon: <FontAwesomeIcon icon={faBookReader} width={16} />,
      id: 'learn',
      href: Constants.ERoutePath.learn,
      isCurrent: router.pathname === Constants.ERoutePath.learn,
    },
    {
      label: 'Portfolio',
      icon: <FontAwesomeIcon icon={faChartPie} width={16} />,
      isCurrent: router.pathname.startsWith('/portfolio'),
      id: 'portfolio',
      href: portfolioURL.root,
    },
    {
      label: 'Saved',
      isCurrent:
        router.pathname === Constants.ERoutePath.savedQueries ||
        router.pathname === Constants.ERoutePath.savedTokens ||
        router.pathname === Constants.ERoutePath.saved,
      icon: <FontAwesomeIcon icon={faHeart} width={16} />,
      id: 'saved',
      href: Constants.ERoutePath.saved,
      onClick: !isLoggedIn ? () => handleLogin : () => null,
    },
    {
      label: 'Alerts',
      isCurrent: router.pathname === Constants.ERoutePath.alerts,
      icon: <FontAwesomeIcon icon={faBell} width={16} />,
      id: 'alerts',
      href: Constants.ERoutePath.alerts,
      onClick: !isLoggedIn ? () => handleLogin : () => null,
    },
    {
      label: 'Account',
      icon: <FontAwesomeIcon icon={faUser} width={16} />,
      isCurrent: router.pathname === Constants.ERoutePath.account,
      id: 'settings',
      href: Constants.ERoutePath.account,
      onClick: !isLoggedIn ? () => handleLogin : () => null,
    },
  ];
};
