import { FC } from 'react';
import Link from 'next/link';
import { Constants } from '@/helpers/utils';
import useAnalyticsStore from '@/stores/analyticsStore';
import color from '@/styles/color';
import { ButtonPrimary } from '../core/Button';
import styles from './styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

export interface IErrorFeedbackProps {
  buttonText?: string;
  feedback?: string;
  helpCenter?: string;
  text?: string;
  title?: string;
}

const ErrorFeedback: FC<IErrorFeedbackProps> = ({
  buttonText = 'Reload page',
  feedback = 'You can reach out for help via:',
  helpCenter = 'Moralis Help Center',
  text = 'Sorry, something went wrong',
  title = 'Well this is embarrassing!',
}) => {
  const tracker = useAnalyticsStore((state) => {
    return state.tracker;
  });
  return (
    <div className={styles.errorWrap}>
      <h1 className={typography.h1} color={color.moneyWhite} style={{ marginBottom: '16px' }}>
        {title}
      </h1>
      <p className={typography.body} color={color.moneyWhite} style={{ marginBottom: '16px' }}>
        {text}
      </p>
      <p className={typography.body} color={color.moneyWhite} style={{ marginBottom: '2px' }}>
        {feedback}
      </p>

      <div className={styles.errorLinks}>
        <Link
          className={typography.captionRegular}
          href={Constants.moralisLinks.discord}
          target="_blank"
          onClick={() => {
            return tracker?.trackButtonClick('Error Boundary Discord Button');
          }}
        >
          {helpCenter}
        </Link>
      </div>

      <ButtonPrimary
        onClick={async () => {
          window.location.reload();
          tracker?.trackButtonClick('Error Boundary Reload Button');
        }}
      >
        {buttonText}
      </ButtonPrimary>
    </div>
  );
};

export default ErrorFeedback;
