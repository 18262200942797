import clsx from 'clsx';
import { isAddress } from 'ethers/lib/utils';
import { BlockiesAvatar } from '@/components/common/BlockiesAvatar';
import { utils } from '@/helpers/utils';
import { extractMultiWalletBalance } from '@/utils/accountSelector/extractMultiWalletBalance';
import { extractSingleWalletBalance } from '@/utils/accountSelector/extractSingleWalletBalance';
import { probeEthAddressSeries } from '@/utils/validateStringAsEthAddresses';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EPortfolioList } from '../manageWallets/libs/types';
import { TList } from '../manageWallets/utils/organizeWalletsByList';
import { useSelectedAccount } from './hooks/useSelected';
import styles from './CurrentlySelected.module.scss';

const CurrentlySelected = ({ onClick, lists, tabId }: { lists: TList; onClick: () => void; tabId: EPortfolioList }) => {
  const { currentlySelectedId, displayId, activeListName } = useSelectedAccount(lists || [], tabId);
  const isAggregate = probeEthAddressSeries(currentlySelectedId);
  const displayedId = !isAggregate ? displayId : activeListName;

  /** Computing currently selected balance (both list and single wallet)  */
  const list = lists[activeListName === 'My Wallets' ? EPortfolioList.MY : EPortfolioList.WATCHED];
  const aggregatedNetWorth = extractMultiWalletBalance(list);
  const selectedAccountBalance = isAggregate
    ? aggregatedNetWorth
    : extractSingleWalletBalance(list, currentlySelectedId);

  return (
    <div
      className={styles.container}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <div className={styles.inner}>
        {currentlySelectedId && (
          <div>
            <BlockiesAvatar address={currentlySelectedId} className={styles.blockie} />
          </div>
        )}
        <span className={styles.span}>
          <span className={clsx('caption-12-medium color-input-active')}>
            {displayedId && isAddress(displayedId) ? (
              <span className={styles.authMethod}>
                <p className={styles.id}>{utils.format.truncate(currentlySelectedId)}</p>
                <p className={clsx('caption-10-regular', 'color-headline-secondary')}>
                  {utils.format.formatAsCurrencyWithoutRounding(selectedAccountBalance, undefined, undefined, 2)}
                </p>
              </span>
            ) : (
              <span className={styles.authMethod}>
                {displayedId && (
                  <p className={styles.id}>
                    {!isAggregate ? displayedId : utils.format.capitalize(utils.format.splitCamelCase(displayedId))}
                  </p>
                )}
                <p className={clsx('caption-10-regular', 'color-headline-secondary')}>
                  {utils.format.formatAsCurrencyWithoutRounding(selectedAccountBalance, undefined, undefined, 2)}
                </p>
              </span>
            )}
          </span>
        </span>
      </div>
      <div>
        <FontAwesomeIcon height={16} icon={faEllipsis} width={16} />
      </div>
    </div>
  );
};

export default CurrentlySelected;
