export const shortenEmail = (email: string) => {
  if (!email) return;
  const parts = email.split('@');
  const localPart = parts[0];
  const domainPart = parts[1].split('.');

  const shortenedLocal = localPart.length > 6 ? localPart.substring(0, 6) + '...' : localPart;
  const shortenedDomain = domainPart[0].length > 7 ? domainPart[0].substring(0, 7) + '...' : domainPart[0];
  const domainExtension = domainPart[1];

  return `${shortenedLocal}@${shortenedDomain}.${domainExtension}`;
};
