import { isAddress } from 'ethers/lib/utils';
import { Dropdown } from 'moralis-ui';
import useCopyToClipboard from '@/helpers/hooks/useCopyToClipboard';
import { utils } from '@/helpers/utils';
import { shortenEmail } from '@/utils/email.utils';
import { faRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { faCopy, faEllipsis, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './OptionsDropdown.module.scss';

const OptionsDropdown = ({
  connectionIcon,
  identifier,
  onDisconnect,
  onLogout,
}: {
  connectionIcon: IconDefinition;
  identifier: string;
  onDisconnect?: () => void;
  onLogout?: () => void;
}) => {
  const { copy } = useCopyToClipboard();

  const handleCopy = () => {
    copy(identifier);
  };

  return (
    <div className={styles.container}>
      <Dropdown position="left" size="lg" trigger={<FontAwesomeIcon icon={faEllipsis} />}>
        <div className={styles.identifier}>
          <span>
            <FontAwesomeIcon icon={connectionIcon} />
            <p className="body-14-regular color-dropdown-active">
              {identifier && isAddress(identifier) ? utils.format.truncate(identifier, 16) : shortenEmail(identifier)}
            </p>
          </span>
          <FontAwesomeIcon
            icon={faCopy}
            onClick={(e) => {
              e.stopPropagation();
              handleCopy();
            }}
          />
        </div>

        {onDisconnect && (
          <>
            <hr className="border-input-default" />
            <div
              className={styles.option}
              id="disconnect-button"
              onClick={(e) => {
                e.stopPropagation();
                onDisconnect();
              }}
            >
              <FontAwesomeIcon icon={faRightFromBracket} />
              <p className="body-12-medium color-input-label">Disconnect Wallet</p>
            </div>
          </>
        )}

        {onLogout && (
          <>
            <hr className="border-input-default" />
            <div
              className={styles.option}
              id="logout-button"
              onClick={(e) => {
                e.stopPropagation();
                onLogout();
              }}
            >
              <FontAwesomeIcon icon={faRightFromBracket} />
              <p className="body-12-medium color-input-label">Logout</p>
            </div>
          </>
        )}
      </Dropdown>
    </div>
  );
};

export default OptionsDropdown;
