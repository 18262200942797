import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import AuthButton from '@/components/common/AuthButton';
import { useSearchStore } from '@/components/SearchV2/hooks/useSearchStore';
import useAuthStore from '@/stores/authStore';
import { AccountSelector } from '../accountSelector/AccountSelector';
import ConnectedOnly from '../accountSelector/ConnectedOnly';
import { HeaderTopBarSearchInput } from './HeaderTopBarSearchInput';
import styles from './HeaderTopBar.module.scss';

export const HeaderTopBar = () => {
  /**
   * This is necessary due to hydration error when user only connects his wallet but does not login.
   * We cannot remove ssr from Header because it will load the header after the page is loaded, which is very bad UI.
   */
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  const authToken = useAuthStore((state) => {
    return state.authToken;
  });
  const { address, connector } = useAccount();

  const { toggleSearchModal } = useSearchStore();

  return (
    <header className={styles.header}>
      <div className={styles.headerRight}>
        <HeaderTopBarSearchInput onClickOpenSearch={toggleSearchModal} />
        {!authToken ? <AuthButton /> : null}
        {isClient && authToken && <AccountSelector />}
        {isClient && address && !authToken && <ConnectedOnly address={address} connectorName={connector?.name} />}
      </div>
    </header>
  );
};
