import clsx from 'clsx';
import { Card } from 'moralis-ui';
import { BlockiesAvatar } from '@/components/common/BlockiesAvatar';
import { utils } from '@/helpers/utils';
import { faCheck, faWallet } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OptionsDropdown from './OptionsDropdown';
import styles from './AccountConnected.module.scss';

const AccountConnected = ({
  address,
  connectorName,
  onAccountClick,
  onDisconnect,
  selectedAccountAddress,
}: {
  address: string;
  connectorName?: string;
  isOptionDropdownVisible: boolean;
  onAccountClick: () => void;
  onClickOutside: () => void;
  onDisconnect: () => void;
  onEllipsisClick: () => void;
  selectedAccountAddress?: string;
}) => {
  return (
    <Card
      className={clsx(styles.cardAccountConnected, {
        [styles.isSelected]: selectedAccountAddress === address,
      })}
      id="smart-wallet-card"
      size="xs"
      style={{
        cursor: 'pointer',
      }}
    >
      <div className={styles.containerConnected} onClick={onAccountClick}>
        <BlockiesAvatar address={address} />

        <div>
          <span>
            <div>
              <p className="body-14-regular color-dropdown-active">{utils.format.truncate(address)}</p>
              <p className="caption-10-capitalize color-input-active">{connectorName || 'Connected Wallet'}</p>
            </div>

            <div
              className={styles.icons}
              style={{
                position: 'relative',
                pointerEvents: 'auto',
              }}
            >
              {selectedAccountAddress === address && <FontAwesomeIcon icon={faCheck} />}
              <OptionsDropdown connectionIcon={faWallet} identifier={address} onDisconnect={onDisconnect} />
            </div>
          </span>
        </div>
      </div>
    </Card>
  );
};

export default AccountConnected;
