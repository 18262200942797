import { useState } from 'react';
import { logApp } from '@/utils/logApp';

const log = logApp.create('useCopyToClipboard');

const useCopyToClipboard = () => {
  const [copiedText, setCopiedText] = useState<string | null>(null);

  const copy = async (text: string) => {
    if (!navigator?.clipboard) {
      log.warn('Clipboard not supported', null);
    }
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
    } catch (error) {
      log.error('Copy failed', error, { text });
      setCopiedText(null);
    }
  };

  return { copiedText, copy };
};

export default useCopyToClipboard;
