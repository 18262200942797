import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Card } from 'moralis-ui';
import { useDisconnect } from 'wagmi';
import { BlockiesAvatar } from '@/components/common/BlockiesAvatar';
import { utils } from '@/helpers/utils';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccountConnected from './AccountConnected';
import NoTradingAccount from './NoTradingAccount';
import styles from './ConnectedOnly.module.scss';

const ConnectedOnly = ({ address, connectorName }: { address: string; connectorName?: string }) => {
  const { disconnectAsync } = useDisconnect();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const ellipsisRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOptionDropdownOpen, setIsOptionDropdownOpen] = useState(false);

  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef?.current?.contains(event.target as Node) &&
        ellipsisRef.current &&
        !ellipsisRef?.current?.contains(event?.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    }
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isDropdownOpen, dropdownRef, ellipsisRef]);

  return (
    <>
      <Card className={styles.card} size="sm">
        <div
          className={styles.container}
          ref={ellipsisRef}
          onClick={(event) => {
            event.stopPropagation();
            setIsDropdownOpen(!isDropdownOpen);
          }}
        >
          <div>
            <BlockiesAvatar address={address} />
            <span>
              <p className={clsx('caption-12-medium color-input-active', styles.identifier)}>
                {utils.format.truncate(address)}
              </p>
              <p className={clsx('caption-10-medium color-input-default', styles.identifier)}>
                {connectorName || 'Connected Wallet'}
              </p>
            </span>
          </div>

          <div>
            <FontAwesomeIcon icon={faEllipsis} />
          </div>
        </div>
      </Card>

      <div
        className={clsx(styles.trade_dropdown, styles.dropdown, {
          [styles.visible]: isDropdownOpen,
        })}
        ref={dropdownRef}
      >
        <NoTradingAccount />

        <AccountConnected
          address={address}
          connectorName={connectorName}
          isOptionDropdownVisible={isOptionDropdownOpen}
          selectedAccountAddress={address}
          onAccountClick={() => null}
          onClickOutside={() => {
            setIsOptionDropdownOpen(false);
          }}
          onDisconnect={() => {
            disconnectAsync();
          }}
          onEllipsisClick={() => setIsOptionDropdownOpen(!isOptionDropdownOpen)}
        />
      </div>
    </>
  );
};

export default ConnectedOnly;
