import { useState } from 'react';
import clsx from 'clsx';
import { Card, Loader } from 'moralis-ui';
import { canAddNewWallet } from '../manageWallets/utils/canAddNewWallet';
import { useAccountSelector } from './hooks/useAccountSelector';
import { Buttons } from './Buttons';
import CurrentlySelected from './CurrentlySelected';
import { ListTabs } from './ListTabs';
import { WalletList } from './WalletList';
import styles from './AccountSelector.module.scss';

export const AccountSelector = () => {
  const { data, isLoading, tabId, setTabId, list, mergedLists } = useAccountSelector();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  if (!data) {
    return <Loader />;
  }

  return (
    <Card className={styles.card} size="sm" transparent>
      <CurrentlySelected lists={data} tabId={tabId} onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
      <div>
        <div
          className={clsx(styles.trade_dropdown, styles.dropdown, {
            [styles.visible]: isDropdownOpen,
          })}
        >
          <ListTabs onClick={setTabId} tabId={tabId} />
          {list && (
            <>
              <WalletList list={list} tabId={tabId} />
              <Buttons canAddNewWallet={canAddNewWallet(mergedLists)} isLoading={isLoading} />
            </>
          )}
        </div>
      </div>
    </Card>
  );
};
