import dynamic from 'next/dynamic';
import errorCatcher from '@/components/errorBoundary';

const { ErrorBoundary, ErrorFeedback } = errorCatcher;

const AuthModalTemplate = dynamic(
  () => import('@/components/authModal/AuthModalTemplate').then((mod) => mod.AuthModalTemplate),
  {
    ssr: false,
  },
);

const GuestModeBanner = dynamic(
  () => {
    return import('@/components/common/GuestModeBanner');
  },
  {
    ssr: false,
  },
);
const HeaderMenu = dynamic(() => import('@/components/aside/HeaderMenu/HeaderMenu').then((mod) => mod.HeaderMenu), {
  ssr: false,
});
const BottomMenu = dynamic(() => import('@/components/aside/BottomMenu/BottomMenu').then((mod) => mod.BottomMenu), {
  ssr: false,
});

const NotificationBanner = dynamic(
  () => {
    return import('@/components/common/NotificationBanner');
  },
  {
    ssr: false,
  },
);
const SmartNotificationBanner = dynamic(
  () => {
    return import('@/components/common/SmartNotificationBanner');
  },
  {
    ssr: false,
  },
);
const Preloader = dynamic(
  () => {
    return import('@/components/common/Preloader');
  },
  {
    ssr: false,
  },
);

const QueryModal = dynamic(
  () => {
    return import('@/components/queryNotificationModal');
  },
  {
    ssr: false,
  },
);

const layoutComponents = {
  AuthModalTemplate,
  ErrorBoundary,
  ErrorFeedback,
  GuestModeBanner,
  HeaderMenu,
  BottomMenu,
  NotificationBanner,
  Preloader,
  QueryModal,
  SmartNotificationBanner,
};

export default layoutComponents;
