import { ButtonPrimary, Empty } from 'moralis-ui';
import { useAuthModal } from '@/stores/modalStore';
import styles from './NoTradingAccount.module.scss';

const NoTradingAccount = () => {
  const { openAuthModal } = useAuthModal();
  return (
    <div className={styles.noTradingAccount}>
      <Empty
        cta={
          <ButtonPrimary size="xs" onClick={openAuthModal}>
            Login
          </ButtonPrimary>
        }
        text="Login and verify your free account for full access to Moralis. You can still trade with your connected wallet in guest mode."
        title="Login to Moralis"
      />
    </div>
  );
};

export default NoTradingAccount;
