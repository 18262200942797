import clsx from 'clsx';
import { Dropdown } from 'moralis-ui';
import { useLogout } from '@/helpers/hooks';
import useCopyToClipboard from '@/helpers/hooks/useCopyToClipboard';
import { Constants, utils } from '@/helpers/utils';
import { faPieChart, faRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { faCopy, faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './WalletOptions.module.scss';

export const WalletOptions = ({
  id,
  isLoggedInAccount,
  onViewPortfolio,
  ens,
}: {
  ens?: string;
  id?: string;
  isLoggedInAccount: boolean;
  onViewPortfolio: () => void;
}) => {
  const { handleLogout } = useLogout();
  const { copy } = useCopyToClipboard();

  return (
    <Dropdown
      position="bottom"
      size="sm"
      trigger={<FontAwesomeIcon height={12} icon={faEllipsis} role="button" width={12} />}
    >
      <div className={styles.container}>
        {id && (
          <div className={clsx(styles.id, 'color-dropdown-active')}>
            <span>
              <p className="body-12-regular">{utils.format.truncate(id, 16)}</p>
              <FontAwesomeIcon height={12} icon={faCopy} role="button" width={12} onClick={() => copy(id)} />
            </span>
            {ens && (
              <span>
                <p className={clsx('caption-12-regular', 'color-headline-secondary')}>{ens}</p>
                <FontAwesomeIcon height={12} icon={faCopy} role="button" width={12} onClick={() => copy(ens)} />
              </span>
            )}
          </div>
        )}
        <div className={clsx(styles.item, 'color-input-label')} role="button" onClick={onViewPortfolio}>
          <FontAwesomeIcon height={12} icon={faPieChart} width={12} />
          <p className="body-12-medium">View Portfolio</p>
        </div>
        {isLoggedInAccount && (
          <div
            className={clsx(styles.item, 'color-input-label')}
            role="button"
            onClick={() =>
              handleLogout({
                notification: {
                  message: 'Come back tomorrow to collect your beans!',
                  title: 'Logged Out',
                  type: 'success',
                },
                redirectUrl: Constants.ERoutePath.home,
              })
            }
          >
            <FontAwesomeIcon height={12} icon={faRightFromBracket} width={12} />
            <p className="body-12-medium">Logout</p>
          </div>
        )}
      </div>
    </Dropdown>
  );
};
